<template>
  <el-dialog :title="title" width="600px" @open="init()" :visible.sync="visible">
    <el-form ref="form" :model="form" label-width="100px" :rules="rules" size="small">
      <el-form-item label="节日名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="放假时间" prop="holidayRestDate">
        <el-date-picker value-format="yyyy-MM-dd" style="width:100%" type="dates" v-model="form.holidayRestDate" />
      </el-form-item>
      <el-form-item label="上班时间" prop="holidayWorkDate">
        <el-date-picker value-format="yyyy-MM-dd" style="width:100%" type="dates" v-model="form.holidayWorkDate" />
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model="form.remarks" type="textarea" :rows="5">

        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button size="small" @click="$emit('change', false)">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submit" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  model: {
    prop: "show",
    event: "change"
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        name: [{ required: true, message: '名称必填' }],
        holidayRestDate: [{ required: true, message: '放假时间必填' }]
      },
    }
  },
  computed: {
    title() {
      return this.row.id ? '修改' : '新增'
    },
    visible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('change', val)
      }
    }
  },
  methods: {
    init() {
      if (this.$refs.form) this.$refs.form.resetFields();
      if (this.row.id) {
        this.form = { ...this.row }
      } else {
        this.form = {
          name: null,
          holidayRestDate:null,
          holidayWorkDate: null,
          remarks: null,
          schedulingHolidayId: this.type.id
        }
      }
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let api, msg, event;
          if (this.row.id) {
            api = '/platform-admin/scheduling-holiday-details/update';
            msg = '修改成功！';
            event = 'edit';
          } else {
            api = '/platform-admin/scheduling-holiday-details/save';
            msg = '添加成功！';
            event = 'add';
          }
          this.loading = true;
          this.$post(api, this.form).then(res => {
            if (res.code === 0) {
              this.$message.success(msg);
              this.$emit(event)
              this.$emit('change', false);
            }
          }).finally(()=>{
              this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped >
</style>
